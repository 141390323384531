import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { Subscription, from } from 'rxjs';
import { ConfirmationModal } from './../../../../core/popup/confirmation.modal';
import { BuildingWizardModel } from './../building-wizard-model';
import { BuildingWizardNavigationPaths } from './../building-wizard-navigation-paths';
import { BuildingWizardService } from './../building-wizard.service';
import { ODataCoreService } from '../../../../core/odata-services/odata.coreapi.service';
import { Common } from '../../../../core/odata/odata.coreapi';

@Component({
  selector: 'app-building-wizard-data',
  templateUrl: './building-wizard-data.component.html',
  styleUrls: ['./building-wizard-data.component.scss']
})
export class BuildingWizardDataComponent implements OnInit, OnDestroy {

  model: BuildingWizardModel;

  buildingId: string;

  addressChanged: boolean;

  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  model2: Common.AddressGeoCoded = <Common.AddressGeoCoded>{};

  @Output()
  pageChangeRequest = new EventEmitter<BuildingWizardNavigationPaths>();

  constructor(
    private modalService: NgbModal,
    private service: BuildingWizardService,
    private translateService: TranslateService,
    private toasterService: ToasterService,
    private odataCoreService: ODataCoreService
  ) {}

  ngOnInit() {
    this.model = this.service.getCurrentBuilding();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  close(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant('BuildingWiz._modal_title');
      modalRef.componentInstance.message = this.translateService.instant('BuildingWiz._modal_message');
      modalRef.componentInstance.yesButton = this.translateService.instant('BuildingWiz._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('BuildingWiz._modal_cancel');

      modalRef.result
        .then(val => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.service.resetCurrentBuilding();
            this.pageChangeRequest.emit(BuildingWizardNavigationPaths.EXIT);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        });
    } else {
      this.pageChangeRequest.emit(BuildingWizardNavigationPaths.EXIT);
    }
  }

  async next(validForm: boolean): Promise<void> {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }
    if (!this.model.latitude || !this.model.longitude || this.addressChanged) {
      if (this.model.location.Latitude && this.model.location.Longitude) {
        // coordinates from geocode call

        this.model.latitude = this.model.location.Latitude;
        this.model.longitude = this.model.location.Longitude;
        this.toasterService.pop('info', '', this.translateService.instant('BuildingWizData._changedCoords'));
        this.pageChangeRequest.emit(BuildingWizardNavigationPaths.NEXT);
      } else {
        // get coordinates separatelly
        this.model2.Street = this.model.address.Street;
        this.model2.No = this.model.address.No;
        this.model2.PostCode = this.model.address.PostCode;
        this.model2.City = this.model.address.City;
        try {
          let result = await this.odataCoreService.Actions().GeoCodeInTools().Parameters(this.model2).Execute();
          if (result) {
            this.model.latitude = result['location']['latitude'];
            this.model.longitude = result['location']['longitude'];
          } else {
            // default coords - middle of germany
            this.model.latitude = 51.163375;
            this.model.longitude = 10.447683;
          }
          this.toasterService.pop('info', '', this.translateService.instant('BuildingWizData._changedCoords'));
          this.pageChangeRequest.emit(BuildingWizardNavigationPaths.NEXT);
        }
      catch(err) {
        this.pageChangeRequest.emit(BuildingWizardNavigationPaths.NEXT);
      } 
                        
                 
      }
    } else {
      this.pageChangeRequest.emit(BuildingWizardNavigationPaths.NEXT);
    }
  }

  validateNumberInput(event: KeyboardEvent): void {
    const key = event.key;
    
    // Check if the character is a digit (0-9)
    if (key < '0' || key > '9') {
      event.preventDefault();
    }
  }

  validateYearInput(event: KeyboardEvent, control: any): void {
    const key = event.key;
    const input = event.target as HTMLInputElement;

    // Initialize an empty errors object
    let errors = control.control.errors || {};

    // Check if the character is a digit (0-9)
    this.validateNumberInput(event);

    // Check if the input starts with '0' and prevent it
    if (input.value.length === 0 && key === '0') {
      event.preventDefault();
      errors['invalidStart'] = true;
    } else {
      delete errors['invalidStart'];
    }

    // Check if the input length exceeds 5 characters
    if (input.value.length >= 5) {
      event.preventDefault();
      errors['maxLengthExceeded'] = true;
    } else {
      delete errors['maxLengthExceeded'];
    }

    // Check if the entire input value is greater than 1000
    if (parseInt(input.value + key, 10) <= 1000) {
      errors['valueTooLow'] = true;
    } else {
      delete errors['valueTooLow'];
    }

    if (key === 'Enter') {
      event.preventDefault();
      return;
    }

    // Set the errors on the control
    control.control.setErrors(Object.keys(errors).length ? errors : null);
  }
}
