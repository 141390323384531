import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subscription, from } from 'rxjs';

import { PropertyCore } from '../../../core/odata/odata.coreapi';
import { Selectable } from '../../../core/model/common';
import { InvoiceDocumentTypeName } from '../../../core/model/incident';
import { FilterByPipe } from '../../../core/pipes/filter.pipe';
import { Utils } from '../../../core/tools/utils';
import { ODataCoreService } from '../../../core/odata-services/odata.coreapi.service';
import { map } from 'rxjs/internal/operators/map';
import { Guid } from 'guid-typescript';
import { Building } from 'app/core/model/building';
import { InspectionDuty } from 'app/core/model/inspectionDuty';


@Component({
  selector: 'app-export-incidents',
  templateUrl: './export-incidents.component.html',
  styleUrls: ['./export-incidents.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExportIncidentsComponent implements OnInit, OnDestroy {
  @Input()
  title: string;
  buildings: Selectable<PropertyCore.Building>[];
  filteredBuildings: Selectable<PropertyCore.Building>[];
  searchFilter: string = '';
  formSubmitted: boolean;
  subscriptions: Subscription = new Subscription();
  private COL_DELIMITER = ';';
  private LINE_DELIMITER = '\r\n';

  constructor(
    private filterByPipe: FilterByPipe,
    private translateService: TranslateService,
    private odataCoreService: ODataCoreService
  ) {
  }

  get selectedCount(): number {
    return this.filteredBuildings ? this.filteredBuildings.filter(x => x.selected).length : 0;
  }

  ngOnInit() {
    this.subscriptions.add(from(this.odataCoreService.Building.Query()
      .OrderBy("Name", "asc")
      .Exec().then(x => x.value))
      .pipe(map(res => res.map(i => {
        return Utils.mapAllJSONDatesToDates(i);
      })))
      .subscribe(res => {
        this.buildings = res;
        this.filteredBuildings = this.buildings;
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onSearchFilterChange(newValue: string) {
    this.searchFilter = newValue;
    this.filteredBuildings = this.filterByPipe.transform(this.buildings, this.searchFilter, ['Name', 'Address.Street', 'Address.PostCode', 'Address.City']);
  }

  selectAll(selectAll: boolean): void {
    this.filteredBuildings.forEach(building => building.selected = selectAll);
  }

  export(validForm: boolean) {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }

    const ids = this.filteredBuildings.filter(x => x.selected).map(x => x.Identity);
    const observables = [];
    ids.forEach(id => observables.push(from(this.odataCoreService.Building
      .Get()
      .Key(Guid.parse((id)))
      .Select("Identity", "Name")
      .Expand(x => {
        x.Expand("Equipments", y => {
          y.Expand("Events", z => {
            z.Select("Identity")
            z.Select("Guidelines")
            z.Select("Qualification")
            z.Select("RefDate")
            z.Select("DueDate")
            z.Select("IsSkipped")
            z.Select("Type")
            z.Expand("Schedule", a => {
              a.Select("Identity")
              a.Select("Cycle")
              a.Select("CycleUnit")
              a.Expand("InspectionDuty", b => {
                b.Expand("OperatorTask")
              })
            })
            z.Expand("Response", a => {
              a.Select("Identity")
              a.Select("DefectRating")
              a.Select("IsDefectProcessed")
              a.Select("Comment")
              a.Select("IsServiceComplete")
              a.Select("Created")
              a.Expand("User", b => {
                b.Select("Identity")
                b.Select("Email")
              })
            })
            z.Expand("Module", a => {
              a.Select("Identity")
              a.Select("Name")
              a.Select("CustomId")
            })
            z.Expand("ServiceProvider", a => {
              a.Select("Identity")
              a.Select("Name")
            })
            z.Expand("Documents", a => {
              a.Expand("DocumentType", b => {
                b.Select("Name")
              })
              a.Select("Identity")
            })
          })
          y.Expand("QuickResponseCodes", z => {
            z.Select("Id")
          })
          y.Select("TuevNo", "Name")
        })
        x.Expand("Events", y => {
          y.Select("Identity")
          y.Select("Guidelines")
          y.Select("Qualification")
          y.Select("RefDate")
          y.Select("DueDate")
          y.Select("IsSkipped")
          y.Select("Type")
          y.Expand("Schedule", z => {
            z.Select("Identity")
            z.Select("Cycle")
            z.Select("CycleUnit")
          })
          y.Expand("Response", z => {
            z.Select("Identity")
            z.Select("DefectRating")
            z.Select("IsDefectProcessed")
            z.Select("Comment")
            z.Select("IsServiceComplete")
            z.Select("Created")
            z.Expand("User", a => {
              a.Select("Identity")
              a.Select("Email")
            })
          })
          y.Expand("Module", z => {
            z.Select("Identity")
            z.Select("Name")
            z.Select("CustomId")
          })
          y.Expand("ServiceProvider", z => {
            z.Select("Identity")
            z.Select("Name")
          })
          y.Expand("Documents", z => {
            z.Expand("DocumentType", a => {
              a.Select("Name")
            })
            z.Select("Identity")
          })
        })
      })
      .Exec().then(x => x.value))
      .pipe(map(res => res.map(i => {
        Utils.mapAllJSONDatesToDates(i);
        return Object.assign(new Building(), i);
      })))));
    this.subscriptions.add(combineLatest(...observables).subscribe((...res) => {
      let csv = this.generateCSVHeader();
      res[0].forEach((buildingEvents: Building[]) => {
        csv += this.generateCSV(buildingEvents);
      });
      this.downloadCSV(csv);
    }));
  }




  private generateCSVHeader(): string {
    let result = '';

    // header row
    result += 'Gebäude' + this.COL_DELIMITER;
    result += 'Bauteil' + this.COL_DELIMITER;
    result += 'EQ Nummer' + this.COL_DELIMITER;
    result += 'QR Code' + this.COL_DELIMITER;
    result += 'Name' + this.COL_DELIMITER;
    result += 'Individuelle Bez.' + this.COL_DELIMITER;
    result += 'Tätigkeitstyp' + this.COL_DELIMITER;
    result += 'Grundlage' + this.COL_DELIMITER;
    result += 'Intervall' + this.COL_DELIMITER;
    result += 'Qualifikation' + this.COL_DELIMITER;
    result += 'Firma' + this.COL_DELIMITER;
    result += 'Durchgeführt' + this.COL_DELIMITER;
    result += 'Fälligkeit' + this.COL_DELIMITER;
    result += 'Angebot' + this.COL_DELIMITER;
    result += 'Beauftragung' + this.COL_DELIMITER;
    result += 'Protokoll' + this.COL_DELIMITER;
    result += 'Regiebericht' + this.COL_DELIMITER;
    result += 'Rechnung' + this.COL_DELIMITER;
    result += 'Mängel vorhanden' + this.COL_DELIMITER;
    result += 'Mängel bearbeitet' + this.COL_DELIMITER;
    result += 'Bemerkung' + this.COL_DELIMITER;
    result += 'Leistung vollständig' + this.COL_DELIMITER;
    result += 'Datum Rückmeldung' + this.COL_DELIMITER;
    result += 'Benutzer Rückmeldung' + this.COL_DELIMITER;
    result += 'Status';
    result += this.LINE_DELIMITER;

    return result;
  }

  private generateCSV(building: Building[]): string {
    let result = '';

    // data rows
    let x = 0;
    while (building[0]?.Events?.length > x) {
      result += building[0].Name + this.COL_DELIMITER; // Gebäude
      result += '' + this.COL_DELIMITER // Bauteil
      result += '' + this.COL_DELIMITER // EQ Nummer
      result += '' + this.COL_DELIMITER; // QR Code
      result += '' + this.COL_DELIMITER; // Name
      result += '' + this.COL_DELIMITER; // Individuelle Bez.
      result += this.translateService.instant('EventType.' + building[0]?.Events[x]?.Type) + this.COL_DELIMITER; // Tätigkeitstyp
      result += (building[0]?.Events[x]?.Guidelines ? building[0]?.Events[x]?.Guidelines : '') + this.COL_DELIMITER; // Grundlage
      result += (building[0]?.Events[x]?.Schedule ? (building[0]?.Events[x]?.Schedule.Cycle + ' ' + this.translateService.instant('CycleUnit2.' + building[0]?.Events[x]?.Schedule.CycleUnit)) : this.translateService.instant('CycleUnit2.NotSpecified')) + this.COL_DELIMITER; // Intervall
      result += (building[0]?.Events[x]?.Qualification ? building[0]?.Events[x]?.Qualification : '') + this.COL_DELIMITER; // Qualifikation
      result += (building[0]?.Events[x]?.ServiceProvider ? building[0]?.Events[x]?.ServiceProvider.Name : '') + this.COL_DELIMITER; // Firma
      result += (building[0]?.Events[x]?.RefDate ? Utils.performPipeOperation('dateFormat', building[0]?.Events[x]?.RefDate) : '') + this.COL_DELIMITER; // Durchgeführt
      result += (building[0]?.Events[x]?.DueDate ? Utils.performPipeOperation('dateFormat', building[0]?.Events[x]?.DueDate) : '') + this.COL_DELIMITER; // Fälligkeit
      result += (building[0]?.Events[x]?.Documents.find(d => d.DocumentType.Name === InvoiceDocumentTypeName.Offer) ? 'x' : '') + this.COL_DELIMITER; // Angebot
      result += (building[0]?.Events[x]?.Documents.find(d => d.DocumentType.Name === InvoiceDocumentTypeName.Commission) ? 'x' : '') + this.COL_DELIMITER; // Beauftragung
      result += (building[0]?.Events[x]?.Documents.find(d => d.DocumentType.Name === InvoiceDocumentTypeName.Protocol) ? 'x' : '') + this.COL_DELIMITER; // Protokoll
      result += (building[0]?.Events[x]?.Documents.find(d => d.DocumentType.Name === InvoiceDocumentTypeName.Report) ? 'x' : '') + this.COL_DELIMITER; // Regiebericht
      result += (building[0]?.Events[x]?.Documents.find(d => d.DocumentType.Name === InvoiceDocumentTypeName.Invoice) ? 'x' : '') + this.COL_DELIMITER; // Rechnung
      result += (building[0]?.Events[x]?.Response && DefectShort(building[0]?.Events[x]?.Response?.DefectRating) ? 'Ja' : 'Nein') + this.COL_DELIMITER; // Mängel vorhanden
      result += (building[0]?.Events[x]?.Response && building[0]?.Events[x]?.Response?.IsDefectProcessed ? 'Ja' : 'Nein') + this.COL_DELIMITER; // Mängel bearbeitet
      result += (building[0]?.Events[x]?.Response && building[0]?.Events[x]?.Response?.Comment ? building[0]?.Events[x]?.Response?.Comment : '') + this.COL_DELIMITER; // Bemerkung
      result += (building[0]?.Events[x]?.Response && building[0]?.Events[x]?.Response?.IsServiceComplete ? 'Ja' : 'Nein') + this.COL_DELIMITER; // Leistung vollständig
      result += (building[0]?.Events[x]?.Response ? Utils.performPipeOperation('dateFormat', building[0]?.Events[x]?.Response?.Created) : '') + this.COL_DELIMITER; // Datum Rückmeldung
      result += (building[0]?.Events[x]?.Response && building[0]?.Events[x]?.Response?.User ? building[0]?.Events[x]?.Response?.User?.Email : '') + this.COL_DELIMITER; // Benutzer Rückmeldung
      result += this.translateService.instant('IncidentStatus.' + building[0].Status(x)); // Status
      result += this.LINE_DELIMITER;
      x++;
    }

    let y = 0;
    while (building[0]?.Equipments?.length > y) {

      let z = 0;
      while (building[0]?.Equipments[y]?.Events?.length > z) {
        result += building[0].Name + this.COL_DELIMITER; // Gebäude
        result += (building[0]?.Equipments[y]?.Events[z]?.Module ? 'Baugruppe' : (building[0].Equipments[y] ? 'Anlage' : '')) + this.COL_DELIMITER; // Bauteil
        result += (building[0]?.Equipments[y] ? building[0]?.Equipments[y]?.TuevNo : '') + this.COL_DELIMITER; // EQ Nummer
        result += (building[0].Equipments[y] ? ((building[0]?.Equipments[y]?.QuickResponseCodes && building[0]?.Equipments[y]?.QuickResponseCodes.length) ? building[0]?.Equipments[y]?.QuickResponseCodes[0].Id : '') : '') + this.COL_DELIMITER; // QR Code
        result += (building[0]?.Equipments[y]?.Events[z]?.Module ? building[0]?.Equipments[y]?.Events[z]?.Module.Name : (building[0].Equipments[y] ? building[0]?.Equipments[y]?.Name.trim() : '')) + this.COL_DELIMITER; // Name
        result += (building[0]?.Equipments[y]?.Events[z]?.Module ? building[0]?.Equipments[y]?.Events[z]?.Module.CustomId : (building[0].Equipments[y] ? building[0]?.Equipments[y]?.CustomId : '')) + this.COL_DELIMITER; // Individuelle Bez.
        result += this.translateService.instant('EventType.' + building[0]?.Equipments[y]?.Events[z]?.Type) + this.COL_DELIMITER; // Tätigkeitstyp
        result += (building[0]?.Equipments[y]?.Events[z]?.Schedule?.InspectionDuty?.OperatorTask?.Guidelines ? building[0]?.Equipments[y]?.Events[z]?.Schedule?.InspectionDuty?.OperatorTask?.Guidelines : building[0]?.Equipments[y]?.Events[z]?.Guidelines ? building[0]?.Equipments[y]?.Events[z]?.Guidelines : '') + this.COL_DELIMITER; // Grundlage
        result += (building[0]?.Equipments[y]?.Events[z]?.Schedule ? (building[0]?.Equipments[y]?.Events[z]?.Schedule.Cycle + ' ' + this.translateService.instant('CycleUnit2.' + building[0]?.Equipments[y]?.Events[z]?.Schedule.CycleUnit)) : this.translateService.instant('CycleUnit2.NotSpecified')) + this.COL_DELIMITER; // Intervall
        result += (building[0]?.Equipments[y]?.Events[z]?.Schedule?.InspectionDuty?.OperatorTask?.Qualification ? building[0]?.Equipments[y]?.Events[z]?.Schedule?.InspectionDuty?.OperatorTask?.Qualification : building[0]?.Equipments[y]?.Events[z]?.Qualification ? building[0]?.Equipments[y]?.Events[z]?.Qualification : '') + this.COL_DELIMITER; // Qualifikation
        result += (building[0]?.Equipments[y]?.Events[z]?.ServiceProvider ? building[0]?.Equipments[y]?.Events[z]?.ServiceProvider.Name : '') + this.COL_DELIMITER; // Firma
        result += (building[0]?.Equipments[y]?.Events[z]?.RefDate ? Utils.performPipeOperation('dateFormat', building[0]?.Equipments[y]?.Events[z]?.RefDate) : '') + this.COL_DELIMITER; // Durchgeführt
        result += (building[0]?.Equipments[y]?.Events[z]?.DueDate ? Utils.performPipeOperation('dateFormat', building[0]?.Equipments[y]?.Events[z]?.DueDate) : '') + this.COL_DELIMITER; // Fälligkeit
        result += (building[0]?.Equipments[y]?.Events[z]?.Documents.find(d => d.DocumentType.Name === InvoiceDocumentTypeName.Offer) ? 'x' : '') + this.COL_DELIMITER; // Angebot
        result += (building[0]?.Equipments[y]?.Events[z]?.Documents.find(d => d.DocumentType.Name === InvoiceDocumentTypeName.Commission) ? 'x' : '') + this.COL_DELIMITER; // Beauftragung
        result += (building[0]?.Equipments[y]?.Events[z]?.Documents.find(d => d.DocumentType.Name === InvoiceDocumentTypeName.Protocol) ? 'x' : '') + this.COL_DELIMITER; // Protokoll
        result += (building[0]?.Equipments[y]?.Events[z]?.Documents.find(d => d.DocumentType.Name === InvoiceDocumentTypeName.Report) ? 'x' : '') + this.COL_DELIMITER; // Regiebericht
        result += (building[0]?.Equipments[y]?.Events[z]?.Documents.find(d => d.DocumentType.Name === InvoiceDocumentTypeName.Invoice) ? 'x' : '') + this.COL_DELIMITER; // Rechnung
        result += (building[0]?.Equipments[y]?.Events[z]?.Response && DefectShort(building[0]?.Equipments[y]?.Events[z]?.Response?.DefectRating) ? 'Ja' : 'Nein') + this.COL_DELIMITER; // Mängel vorhanden
        result += (building[0]?.Equipments[y]?.Events[z]?.Response && building[0]?.Equipments[y]?.Events[z]?.Response?.IsDefectProcessed ? 'Ja' : 'Nein') + this.COL_DELIMITER; // Mängel bearbeitet
        result += (building[0]?.Equipments[y]?.Events[z]?.Response && building[0]?.Equipments[y]?.Events[z]?.Response?.Comment ? building[0]?.Equipments[y]?.Events[z]?.Response?.Comment.split("\n").join("\\n") : '') + this.COL_DELIMITER; // Bemerkung
        result += (building[0]?.Equipments[y]?.Events[z]?.Response && building[0]?.Equipments[y]?.Events[z]?.Response?.IsServiceComplete ? 'Ja' : 'Nein') + this.COL_DELIMITER; // Leistung vollständig
        result += (building[0]?.Equipments[y]?.Events[z]?.Response ? Utils.performPipeOperation('dateFormat', building[0]?.Equipments[y]?.Events[z]?.Response?.Created) : '') + this.COL_DELIMITER; // Datum Rückmeldung
        result += (building[0]?.Equipments[y]?.Events[z]?.Response && building[0]?.Equipments[y]?.Events[z]?.Response?.User ? building[0]?.Equipments[y]?.Events[z]?.Response?.User?.Email : '') + this.COL_DELIMITER; // Benutzer Rückmeldung
        result += this.translateService.instant('IncidentStatus.' + building[0].Status(y, z, true)); // Status
        result += this.LINE_DELIMITER;
        z++;
      }
      y++;
    }


    return result;
  }

  private downloadCSV(csv: string): void {
    const filename = 'Tätigkeitsexport.csv';
    const blob = new Blob(['\uFEFF', csv], { type: "text/csv;charset=UTF-8;" });
    //    if (navigator.msSaveBlob) { // IE 10+
    //      navigator.msSaveBlob(blob, filename);
    //    } else {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    //    }
  }
}

function DefectShort(DefectRating): boolean {
  switch (DefectRating) {
    case PropertyCore.DefectRatings.Unrated:
    case PropertyCore.DefectRatings.Minor:
    case PropertyCore.DefectRatings.Major:
    case PropertyCore.DefectRatings.Dangerous:
      return true;
    case PropertyCore.DefectRatings.Undefined:
      return false;
  }
  return false;
};
