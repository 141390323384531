import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToasterService } from "angular2-toaster";
import { Subscription, from } from "rxjs";
import { PropertyCore } from "../../../core/odata/odata.coreapi";
import { Selectable } from "./../../../core/model/common";
import { ODataCoreService } from "../../../core/odata-services/odata.coreapi.service";
import { map } from "rxjs/internal/operators/map";
import { Utils } from "../../../core/tools/utils";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

@Component({
  selector: "app-export-portfolio",
  templateUrl: "./export-portfolio.component.html",
  styleUrls: ["./export-portfolio.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ExportPortfolioComponent implements OnInit, OnDestroy {
  @Input()
  title: string;

  json_obj: any[];
  EXCEL_TYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  EXCEL_EXTENSION = ".xlsx";
  z: number;
  a: number;
  b: number;
  data: Array<Array<any>>;
  first_thirty: string[];
  first_thirty_units: string[];

  buildings: Selectable<PropertyCore.Building>[];
  attributeCategories: PropertyCore.AttributeType[];

  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  constructor(
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private odataCoreService: ODataCoreService
  ) { }

  get selectedCount(): number {
    return this.buildings ? this.buildings.filter((x) => x.selected).length : 0;
  }

  ngOnInit() {
    this.subscriptions.add(
      from(
        this.odataCoreService.Building.Query()
          .OrderBy("Name", "asc")
          .Exec()
          .then((x) => x.value)
      )
        .pipe(
          map((res) =>
            res.map((i) => {
              return Utils.mapAllJSONDatesToDates(i);
            })
          )
        )
        .subscribe((res) => (this.buildings = res))
    );

    this.subscriptions.add(
      from(
        this.odataCoreService.Building.Query()
          .OrderBy("Id", "asc")
          .Expand((x) => {
            x.Expand("Account"),
              x.Expand("Equipments", (y) => {
                y.Expand("QuickResponseCodes", (z) => {
                  z.Select("Value");
                }),
                  y.Expand("Modules", (z) => {
                    z.Expand("Room", (t) => {
                      t.Select("Name"),
                        t.Expand("Floor", (f) => {
                          f.Select("Name");
                        });
                    });
                    z.Expand("AttributeValues"),
                      z.Expand("ModuleType", (t) => {
                        t.Select("Code");
                      });
                  }),
                  y.Expand("Images", (z) => {
                    z.Select("Id");
                  });
                y.Expand("EquipmentType", (z) => {
                  z.Select("Code"),
                    z.Select("SubNo"),
                    z.Select("Name"),
                    z.Expand("CostGroup", (t) => {
                      t.Select("No");
                    });
                }),
                  y.Expand("Room", (z) => {
                    z.Select("Name"),
                      z.Expand("Floor", (t) => {
                        t.Select("Name");
                      });
                  }),
                  y.Expand("AttributeValues");
              });
          })
          .Exec()
          .then((x) => x.value)
      )
        .pipe(
          map((res) =>
            res.map((i) => {
              return Utils.mapAllJSONDatesToDates(i);
            })
          )
        )
        .subscribe((res) => (this.buildings = res))
    );

    this.subscriptions.add(
      from(
        this.odataCoreService.AttributeType.Query()
          .Expand((x) => {
            x.Expand("Set");
            x.Expand("MeasurementUnit");
          })
          .OrderBy("Id", "asc")
          .Exec()
          .then((x) => x.value)
      ).subscribe((res2) => (this.attributeCategories = res2))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  selectAll(selectAll: boolean): void {
    this.buildings.forEach((building) => (building.selected = selectAll));
  }

  export(validForm: boolean) {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }

    this.data = [[], []];
    this.z = 0;
    this.a = 0;
    this.b = 2;
    this.first_thirty = [
      "Gebäude ID",
      "Gebäude",
      "Land",
      "Bundesland",
      "Zip",
      "Stadt",
      "Address",
      "Typ",
      "ID ANL",
      "QR ANL",
      "ID BGR",
      "ID Gebäude",
      "Gebäude",
      "Nr. KGR",
      "Nr. KGR (Sub)",
      "K.bez. ANL",
      "L.bez. ANL",
      "Kunden.bez. ANL",
      "Nr. ANL",
      "K.bez. BGR",
      "L.bez. BGR",
      "Nr. BGR",
      "Bemerkung",
      "Anzahl (BGR)",
      "Raum",
      "Ebene",
      "Bearb.-Datum",
      "TÜV EQ-Nr.",
      "Bilder",
    ];

    this.first_thirty_units = [
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
    ];

    for (let i = 0; i < this.first_thirty.length; i++) {
      this.data[0][i] = this.first_thirty[i];
      this.data[1][i] = this.first_thirty_units[i];
    }
    for (let i = 30; i < this.attributeCategories.length + 30; i++) {
      this.data[0][i] = this.attributeCategories[this.z].Name;
      this.data[1][i] = this.attributeCategories[this.z].MeasurementUnit.Name;
      this.z++;
    }
    this.z = 0;

    this.buildings = this.buildings.filter((x) => x.selected);

    while (this.a < this.buildings.length) {
      let c = 0;
      while (c < this.buildings[this.a].Equipments.length) {
        this.data.push([]);
        this.data[this.b][0] = this.buildings[this.a].Id.toString();
        this.data[this.b][1] = this.buildings[this.a].Name;
        this.data[this.b][2] = this.buildings[this.a].Address.CountryInfo.Name;
        this.data[this.b][3] = this.buildings[this.a].Address.RegionInfo.Name;
        this.data[this.b][4] = this.buildings[this.a].Address.PostCode;
        this.data[this.b][5] = this.buildings[this.a].Address.City;
        this.data[this.b][6] = this.buildings[this.a].Address.Street.concat(
          " "
        ).concat(this.buildings[this.a].Address.No);
        this.data[this.b][7] = "ANL";
        this.data[this.b][8] =
          this.buildings[this.a].Equipments[c].Id.toString();
        this.data[this.b][9] = this.buildings[this.a].Equipments[c]
          .QuickResponseCodes.length
          ? this.buildings[this.a].Equipments[c].QuickResponseCodes[0].Value
          : "";
        this.data[this.b][10] = "-";
        this.data[this.b][11] = this.buildings[this.a].Id.toString();
        this.data[this.b][12] = this.buildings[this.a].Name;
        this.data[this.b][13] =
          this.buildings[this.a].Equipments[
            c
          ].EquipmentType.CostGroup.No.toString();
        this.data[this.b][14] =
          this.buildings[this.a].Equipments[c].EquipmentType.SubNo.toString();
        this.data[this.b][15] =
          this.buildings[this.a].Equipments[c].EquipmentType.Code;
        this.data[this.b][16] =
          this.buildings[this.a].Equipments[c].EquipmentType.Name;
        this.data[this.b][17] = this.buildings[this.a].Equipments[c].CustomId;
        this.data[this.b][18] =
          this.buildings[this.a].Equipments[c].InstallNo.toString();
        this.data[this.b][19] = "-";
        this.data[this.b][20] = "-";
        this.data[this.b][21] = "-";
        this.data[this.b][22] = this.buildings[this.a].Equipments[c].Comment;
        this.data[this.b][23] = "-";
        this.data[this.b][24] = this.buildings[this.a].Equipments[c].Room.Name;
        this.data[this.b][25] =
          this.buildings[this.a].Equipments[c].Room.Floor.Name;
        this.data[this.b][26] = this.buildings[this.a].Equipments[
          c
        ].DataTimestamp.toLocaleString().slice(0, 10);
        this.data[this.b][28] = this.buildings[this.a].Equipments[c].TuevNo;
        this.data[this.b][29] = this.buildings[this.a].Equipments[c].Images
          .length
          ? this.buildings[this.a].Equipments[c].Images.length.toString()
          : "-1";

        for (let i = 30; i < this.data[0].length; i++) {
          this.data[this.b][i] = "!!";
        }
        var no_null_values;

        if (this.buildings[this.a].Equipments[c].AttributeValues != null) {
          no_null_values = this.buildings[this.a].Equipments[
            c
          ].AttributeValues.filter((x) => x.Value != null);
        } else {
          no_null_values = null;
        }

        if (no_null_values != null) {
          for (let i = 0; i < no_null_values.length; i++) {
            let attr = this.attributeCategories.find(
              (x) => x.Id == no_null_values[i].Id
            );
            let attr_index = this.data[0].findIndex((x) => x == attr.Name);
            this.data[this.b][attr_index] = no_null_values[i].Value;
          }
        }

        if (this.buildings[this.a].Equipments[c].Modules.length) {
          for (
            let i = 0;
            i < this.buildings[this.a].Equipments[c].Modules.length;
            i++
          ) {
            this.data.push([]);
            this.b++;
            this.data[this.b][0] = this.buildings[this.a].Id.toString();
            this.data[this.b][1] = this.buildings[this.a].Name;
            this.data[this.b][2] =
              this.buildings[this.a].Address.CountryInfo.Name;
            this.data[this.b][3] =
              this.buildings[this.a].Address.RegionInfo.Name;
            this.data[this.b][4] = this.buildings[this.a].Address.PostCode;
            this.data[this.b][5] = this.buildings[this.a].Address.City;
            this.data[this.b][6] = this.buildings[this.a].Address.Street.concat(
              " "
            ).concat(this.buildings[this.a].Address.No);
            this.data[this.b][7] = "BGR";
            this.data[this.b][8] =
              this.buildings[this.a].Equipments[c].Id.toString();
            this.data[this.b][9] = this.buildings[this.a].Equipments[c]
              .QuickResponseCodes.length
              ? this.buildings[this.a].Equipments[c].QuickResponseCodes[0].Value
              : "";
            this.data[this.b][10] =
              this.buildings[this.a].Equipments[c].Modules[i].Id.toString();
            this.data[this.b][11] = this.buildings[this.a].Id.toString();
            this.data[this.b][12] = this.buildings[this.a].Name;
            this.data[this.b][13] =
              this.buildings[this.a].Equipments[
                c
              ].EquipmentType.CostGroup.No.toString();
            this.data[this.b][14] =
              this.buildings[this.a].Equipments[
                c
              ].EquipmentType.SubNo.toString();
            this.data[this.b][15] =
              this.buildings[this.a].Equipments[c].EquipmentType.Code;
            this.data[this.b][16] =
              this.buildings[this.a].Equipments[c].EquipmentType.Name;
            this.data[this.b][17] =
              this.buildings[this.a].Equipments[c].CustomId;
            this.data[this.b][18] =
              this.buildings[this.a].Equipments[c].InstallNo.toString();
            this.data[this.b][19] =
              this.buildings[this.a].Equipments[c].Modules[i].ModuleType.Code;
            this.data[this.b][20] =
              this.buildings[this.a].Equipments[c].Modules[i].Name;
            this.data[this.b][21] =
              this.buildings[this.a].Equipments[c].Modules[
                i
              ].InstallNo.toString();
            this.data[this.b][22] =
              this.buildings[this.a].Equipments[c].Comment;
            this.data[this.b][23] =
              this.buildings[this.a].Equipments[c].Modules[
                i
              ].Quantity.toString();
            this.data[this.b][24] =
              this.buildings[this.a].Equipments[c].Modules[i].Room.Name;
            this.data[this.b][25] =
              this.buildings[this.a].Equipments[c].Modules[i].Room.Floor.Name;
            this.data[this.b][26] = this.buildings[this.a].Equipments[
              c
            ].Modules[i].DataTimestamp.toLocaleString().slice(0, 10);
            this.data[this.b][27] = this.buildings[this.a].Account.Name;
            this.data[this.b][28] = this.buildings[this.a].Equipments[c].TuevNo;
            this.data[this.b][29] = this.buildings[this.a].Equipments[c].Images
              .length
              ? this.buildings[this.a].Equipments[c].Images.length.toString()
              : "-1";

            for (let i = 30; i < this.data[0].length; i++) {
              this.data[this.b][i] = "!!";
            }
            var no_null_values_modules;

            if (
              this.buildings[this.a].Equipments[c].Modules[i].AttributeValues !=
              null
            ) {
              no_null_values_modules = this.buildings[this.a].Equipments[
                c
              ].Modules[i].AttributeValues.filter((x) => x.Value != null);
            } else {
              no_null_values_modules = null;
            }

            if (no_null_values_modules != null) {
              for (let i = 0; i < no_null_values_modules.length; i++) {
                let attr = this.attributeCategories.find(
                  (x) => x.Id == no_null_values_modules[i].Id
                );
                let attr_index = this.data[0].findIndex((x) => x == attr.Name);
                this.data[this.b][attr_index] = no_null_values_modules[i].Value;
              }
            }
          }
        }
        this.b++;
        c++;
      }
      this.a++;
    }

    this.exportAsExcelFile(this.data, "Portfolio");
    this.toasterService.pop(
      "info",
      "",
      this.translateService.instant("Export._export_success")
    );
  }

  private exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.EXCEL_TYPE });
    FileSaver.saveAs(
      data,
      fileName +
      "_" +
      new Date().toISOString().slice(0, 10) +
      "_" +
      new Date().toTimeString().slice(0, 8).replace(":", "-") +
      this.EXCEL_EXTENSION
    );
  }
}
